import React, { useLayoutEffect, useEffect, useState } from 'react'
import clsx from 'clsx';
import { Link } from "react-router-dom"
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Highlight from 'react-highlight'
import hljs from 'highlight.js/lib/core';
import shell from 'highlight.js/lib/languages/shell';
import javascript from 'highlight.js/lib/languages/javascript';
import ruby from 'highlight.js/lib/languages/ruby';
import php from 'highlight.js/lib/languages/php';
import 'highlight.js/styles/stackoverflow-dark.css';

import './styles/styles.css';

const useStyles = makeStyles(theme => ({
    onePage: {
        maxWidth: '100%',
        minHeight: '87vh',
    },
    link: {
        textDecoration: 'none'
    },
    primaryPara: {
        textAlign: 'justify',
        // paddingLeft: '2em',
        // paddingRight: '2em',
    },
    primaryTitles: {
        paddingTop: '2em',
        // paddingLeft: '1.5em',
        color: '#16a09e'
        // color: '#156573'
        // color: '#24b47e'
    },
    subtitle: {
        color: '#156573'
    },
    title: {
        margin: '0 auto',
        textAlign: 'center',
        color: '#156573'
    },
    paragraphs: {
        textAlign: 'justify'
    },
    container: {
        margin: '0.5em'
    },
    codeBlock: {
        margin: '0 auto',
        width: '100%',
        marginTop: 0,
        marginBottom: 0
    },
    languageChoices: {
        textAlign: 'justify',
        backgroundColor: '#1c1b1b',
        color: 'white',
    },
    languageChoice: {
        padding: '0.5em',
        '&:hover': {
            cursor: 'pointer'
        },
    },
    selectedLanguageChoice: {
        border: '1px white solid',
        padding: '0.5em',
        '&:hover': {
            cursor: 'pointer'
        },
    }
}));

const CardBackend = (props) => {
    const classes = useStyles(props)
    const { toggleNavbarTransparent } = props

    useEffect(() => {
        toggleNavbarTransparent(true)
        hljs.registerLanguage('javascript', javascript);
        hljs.initHighlightingOnLoad();
    },[])

    const [language, setLanguage] = useState('javascript')

    const changeLanguage = (targetLanguage) => {
        setLanguage(targetLanguage)
        switch (targetLanguage) {
            case 'javascript':
                hljs.registerLanguage('javascript', javascript);
                hljs.initHighlightingOnLoad();
                break;
            case 'ruby':
                hljs.registerLanguage('ruby', ruby);
                hljs.initHighlightingOnLoad();
                break;
            case 'php':
                hljs.registerLanguage('php', php);
                hljs.initHighlightingOnLoad();
                break;
            default:
                hljs.registerLanguage('javascript', javascript);
                hljs.initHighlightingOnLoad();
                break;
        }
    }

    return (<div className={classes.onePage}>
        <Grid container spacing={3} className={classes.container}>
            {/* Title */}
            <Grid item md={12} xs={12} sm={12}>
                <Typography className={classes.title} variant="h4" gutterBottom>Card Backend Implementation</Typography>
            </Grid>
            {/* Accordion */}
            <Grid item md={2} xs={12} sm={12}></Grid>
            <Grid item md={8} xs={12} sm={12}>
                {/* Initialize Liberalize */}
                <Accordion>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    >
                    <Typography className={classes.subtitle}>Initialize Liberalize</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className={classes.codeBlock}>
                            <div className={classes.languageChoices}>
                                <span onClick={() => { changeLanguage('javascript') }} className={ language === 'javascript' ? classes.selectedLanguageChoice : classes.languageChoice}>NodeJS</span>
                                <span onClick={() => { changeLanguage('ruby') }} className={ language === 'ruby' ? classes.selectedLanguageChoice : classes.languageChoice}>Ruby</span>
                                <span onClick={() => { changeLanguage('php') }} className={ language === 'php' ? classes.selectedLanguageChoice : classes.languageChoice}>PHP</span>
                            </div>
                            {language === 'javascript' && <Highlight className={clsx('javascript', classes.codeBlock)}>
                                {'//'} Require NodeJS Backend SDK {'\n'}
                                const liberalize = require('liberalize-backend-nodejs-sdk');{'\n'}
                                {'//'} Initalize package, passing in your private key and environment {'\n'}
                                {'//'} 'prod' for live production 'staging' for test environment {'\n'}
                                const liberalizeBackEnd = new liberalize.LiberalizeNodeJs("your_private_key", "your_environment") {'\n'}
                            </Highlight>}
                            {language === 'ruby' && <Highlight className={clsx('ruby', classes.codeBlock)}>
                                {'#'} Require Ruby Backend SDK {'\n'}
                                require "liberalize_backend_ruby_sdk"{'\n'}
                                {'#'} Initalize package, passing in your private key and environment {'\n'}
                                {'#'} 'production' for live production 'staging' for test environment {'\n'}
                                liberalizeBackEnd = LiberalizeBackendRubySdk.new("your_private_key", "your_environment") {'\n'}
                            </Highlight>}
                            {language === 'php' && <Highlight className={clsx('php', classes.codeBlock)}>
                                {'//'} Require PHP Backend SDK {'\n'}
                                require __DIR__ . '/vendor/autoload.php';{'\n'}
                                use Liberalize\LiberalizeBackend;{'\n'}
                                {'//'} Initalize package, passing in your private key and environment {'\n'}
                                {'//'} 'production' for live productionm 'staging' for test environment {'\n'}
                                $liberalizeBackEnd = new LiberalizeBackend("your_private_key", "your_environment");{'\n'}
                            </Highlight>}
                        </div>
                    </AccordionDetails>
                </Accordion>
                {/* Create Payment */}
                <Accordion>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    >
                    <Typography className={classes.subtitle}>Create Payment</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className={classes.codeBlock}>
                            <div className={classes.languageChoices}>
                                <span onClick={() => { changeLanguage('javascript') }} className={ language === 'javascript' ? classes.selectedLanguageChoice : classes.languageChoice}>NodeJS</span>
                                <span onClick={() => { changeLanguage('ruby') }} className={ language === 'ruby' ? classes.selectedLanguageChoice : classes.languageChoice}>Ruby</span>
                                <span onClick={() => { changeLanguage('php') }} className={ language === 'php' ? classes.selectedLanguageChoice : classes.languageChoice}>PHP</span>
                            </div>
                            {language === 'javascript' && <Highlight className={clsx('javascript', classes.codeBlock)}>
                                {'//'} Create Payment {'\n'}
                                {'liberalizeBackEnd.createPayment({'}{'\n'}
                                {'\t'}{'amount: 100,'}{'//'}{' We use the lowest denominator. 100 == $1.00'}{'\n'}
                                {'\t'}{'currency: "SGD",'}{'\n'}
                                {'\t'}{'authorize: true, '}{'//'}{' optional* for immediate authorized process'}{'\n'}
                                {'\t'}{'source: "{source}", '}{'//'}{' source is a value returned from frontend SDK, e.g. card-426782-f2501234-0612-43bz-c6e3-9jc83cd80s8f'}{'\n'}
                                {'\t'}{'}, "liberalize_service").then((paymentSchema) => { '}{'//'}{' liberalize_service refers to the point-of-sale\'s service. By default liberalize_service will be "elements"'}{'\n'}
                                {'\t'}{'console.log(paymentSchema);'}{'\n'}
                                {'});'}{'\n'}
                            </Highlight>}
                            {language === 'ruby' && <Highlight className={clsx('ruby', classes.codeBlock)}>
                                {'#'} Create Payment {'\n'}
                                {'paymentSchema = liberalizeBackEnd.createPayment({'}{'\n'}
                                {'\t'}{'amount: 100,'}{'#'}{' We use the lowest denominator. 100 == $1.00'}{'\n'}
                                {'\t'}{'currency: "SGD",'}{'\n'}
                                {'\t'}{'authorize: true, '}{'#'}{' optional* for immediate authorized process'}{'\n'}
                                {'\t'}{'source: "{source}", '}{'#'}{' source is a value returned from frontend SDK, e.g. card-426782-f2501234-0612-43bz-c6e3-9jc83cd80s8f'}{'\n'}
                                {'\t'}{'}, "liberalize_service") '}{'#'}{' liberalize_service refers to the point-of-sale\'s service. By default liberalize_service will be "elements"'}{'\n'}
                            </Highlight>}
                            {language === 'php' && <Highlight className={clsx('php', classes.codeBlock)}>
                                {'//'} Create Payment {'\n'}
                                {'$newPaymentObj = ["amount"=>100,"source"=>"card-xxx-xxxx", "currency"=>"SGD", "authorize" => true];'}{'\n'}
                                {'//'}{'source is a value returned from frontend SDK, e.g. card-426782-f2501234-0612-43bz-c6e3-9jc83cd80s8f'}{'\n'}
                                {'//'}{'authorize=>true, optional* for immediate authorized process'}{'\n'}
                                {"$paymentCreated = $liberalizeBackEnd->createPayment($newPaymentObj, '{liberalize_service}');"}{'\n'}
                                {'//'}{'liberalize_service refers to the point-of-sale\'s service. By default liberalize_service will be "elements"'}{'\n'}
                            </Highlight>}
                        </div>
                    </AccordionDetails>
                </Accordion>
                {/* Authorize Payment */}
                <Accordion>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    >
                    <Typography className={classes.subtitle}>Authorize Payment</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className={classes.codeBlock}>
                            <div className={classes.languageChoices}>
                                <span onClick={() => { changeLanguage('javascript') }} className={ language === 'javascript' ? classes.selectedLanguageChoice : classes.languageChoice}>NodeJS</span>
                                <span onClick={() => { changeLanguage('ruby') }} className={ language === 'ruby' ? classes.selectedLanguageChoice : classes.languageChoice}>Ruby</span>
                                <span onClick={() => { changeLanguage('php') }} className={ language === 'php' ? classes.selectedLanguageChoice : classes.languageChoice}>PHP</span>
                            </div>
                            {language === 'javascript' && <Highlight className={clsx('javascript', classes.codeBlock)}>
                                {'//'} Authorize Payment {'\n'}
                                {'liberalizeBackEnd.authorizePayment({'}{'\n'}
                                {'\t'}{'amount: 100, '}{'//'}{' We use the lowest denominator. 100 == $1.00'}{'\n'}
                                {'\t'}{'source: "{source}", '}{'//'}{' returned from frontend SDK, e.g. card-426782-f2501234-0612-43bz-c6e3-9jc83cd80s8f'}{'\n'}
                                {'\t'}{'paymentId: "{paymentId}", '}{'\n'}
                                {'\t'}{'}, "liberalize_service").then((result) => { '}{'//'}{' liberalize_service refers to the point-of-sale\'s service. By default liberalize_service will be "elements"'}{'\n'}
                                {'\t'}{'console.log(result);'}{'\n'}
                                {'});'}{'\n'}
                            </Highlight>}
                            {language === 'ruby' && <Highlight className={clsx('ruby', classes.codeBlock)}>
                                {'#'} Authorize Payment {'\n'}
                                {'paymentSchema = liberalizeBackEnd.authorizePayment({'}{'\n'}
                                {'\t'}{'amount: 100, '}{'#'}{' We use the lowest denominator. 100 == $1.00'}{'\n'}
                                {'\t'}{'source: "{source}", '}{'#'}{' returned from frontend SDK, e.g. card-426782-f2501234-0612-43bz-c6e3-9jc83cd80s8f'}{'\n'}
                                {'\t'}{'paymentId: "{paymentId}", '}{'\n'}
                                {'\t'}{'}, "liberalize_service") '}{'#'}{' liberalize_service refers to the point-of-sale\'s service. By default liberalize_service will be "elements"'}{'\n'}
                            </Highlight>}
                            {language === 'php' && <Highlight className={clsx('php', classes.codeBlock)}>
                                {'//'} Authorize Payment {'\n'}
                                {'$authorizePaymentObj = ["amount"=>1000,"currency"=> "SGD","source"=>"card-xxxx-xxx-xxx-xxxxxx","paymentId"=>"paymentId", "other"=>["key"=>"value"]];'}{'\n'}
                                {'//'}{'source is returned from frontend SDK, e.g. card-426782-f2501234-0612-43bz-c6e3-9jc83cd80s8f'}{'\n'}
                                {'$paymentAuthorized = $liberalizeBackEnd->authorizePayment($authorizePaymentObj,{liberalize_service});'}{'\n'}
                                {'//'}{"liberalize_service refers to the point-of-sale's service. By default liberalize_service will be 'elements'"}{'\n'}
                            </Highlight>}
                        </div>
                    </AccordionDetails>
                </Accordion>
                {/* Capture Payment */}
                <Accordion>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    >
                    <Typography className={classes.subtitle}>Capture Payment</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className={classes.codeBlock}>
                            <div className={classes.languageChoices}>
                                <span onClick={() => { changeLanguage('javascript') }} className={ language === 'javascript' ? classes.selectedLanguageChoice : classes.languageChoice}>NodeJS</span>
                                <span onClick={() => { changeLanguage('ruby') }} className={ language === 'ruby' ? classes.selectedLanguageChoice : classes.languageChoice}>Ruby</span>
                                <span onClick={() => { changeLanguage('php') }} className={ language === 'php' ? classes.selectedLanguageChoice : classes.languageChoice}>PHP</span>
                            </div>
                            {language === 'javascript' && <Highlight className={clsx('javascript', classes.codeBlock)}>
                                {'//'} Capture Payment {'\n'}
                                {'liberalizeBackEnd.capturePayment({'}{'\n'}
                                {'\t'}{'amount: 100, '}{'//'}{' We use the lowest denominator. 100 == $1.00'}{'\n'}
                                {'\t'}{'paymentId: "{paymentId}", '}{'\n'}
                                {'\t'}{'}, "liberalize_service").then((result) => { '}{'//'}{' liberalize_service refers to the point-of-sale\'s service. By default liberalize_service will be "elements"'}{'\n'}
                                {'\t'}{'console.log(result);'}{'\n'}
                                {'});'}{'\n'}
                            </Highlight>}
                            {language === 'ruby' && <Highlight className={clsx('ruby', classes.codeBlock)}>
                                {'#'} Capture Payment {'\n'}
                                {'paymentSchema = liberalizeBackEnd.capturePayment({'}{'\n'}
                                {'\t'}{'amount: 100, '}{'#'}{' We use the lowest denominator. 100 == $1.00'}{'\n'}
                                {'\t'}{'paymentId: "{paymentId}", '}{'\n'}
                                {'\t'}{'}, "liberalize_service") '}{'#'}{' liberalize_service refers to the point-of-sale\'s service. By default liberalize_service will be "elements"'}{'\n'}
                            </Highlight>}
                            {language === 'php' && <Highlight className={clsx('php', classes.codeBlock)}>
                                {'//'} Capture Payment {'\n'}
                                {'$capturePaymentObj = ["amount"=>1000,"paymentId"=>"{paymentId}"];'}{'\n'}
                                {'$paymentAuthorized = $liberalizeBackEnd->capturePayment($capturePaymentObj, {liberalize_service});'}{'\n'}
                                {'//'}{"liberalize_service refers to the point-of-sale's service. By default liberalize_service will be 'elements'"}{'\n'}
                            </Highlight>}
                        </div>
                    </AccordionDetails>
                </Accordion>
            </Grid>
            <Grid item md={2} xs={12} sm={12}></Grid>
        </Grid>
    </div>)
}

export default CardBackend